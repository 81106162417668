import React from 'react';
import { BannerContentContainer } from "./banner-content.styles";

const BannerContent = () => {
  return (
    <BannerContentContainer>
      <h1>ASAP Marine Supply Co.</h1>
    </BannerContentContainer>
  );
}

export default BannerContent;
